import React from 'react';
import PropTypes from 'prop-types';
import './view.scss';

export const SeoDecription = ({ seoDescription }) => (
    <div className="seoDescription">
        {/* eslint-disable-next-line react/no-danger */}
        {seoDescription ? <section dangerouslySetInnerHTML={{ __html: seoDescription }} /> : null}
    </div>
);

SeoDecription.propTypes = {
    seoDescription: PropTypes.string.isRequired
};
