import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';

import { ArticlesView } from './view';

export const Articles = () => {
    const data = useStaticQuery(graphql`
        query {
            allArticles(filter: {
                is_active: {
                    eq: 1
                }
                type: {
                    eq: "Technologie"
                }
            }) {
                nodes {
                    alternative_id
                    author
                    created_at(formatString: "DD.MM.YYYY")
                    image_main
                    lead
                    subtype
                    slug
                    title
                    type
                    reading_time
                }
            }
        }
    `);

    const types = [...new Set((data?.allArticles?.nodes?.length && data?.allArticles?.nodes?.reduce((allTypes, article) => {
        if (article?.subtype != null) {
            allTypes.push(article?.subtype);
        }
        return allTypes;
    }, [])) || null)];

    const uniqeArticles = (data?.allArticles?.nodes.length && _.uniqBy(data?.allArticles?.nodes, 'alternative_id')) ?? [];

    return (<ArticlesView articles={ uniqeArticles } types={ types } />);
};