import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './view.scss';

export const Breadcrumbs = ({ breadcrumbs }) => {
    let location = '';

    if (typeof window !== 'undefined') {
        location = window.location.origin;
    }

    const breadcrumbsSchema = JSON.stringify(
        breadcrumbs.map((breadcrumb, idx, arr) => {
            // if this is the last element of breadcrumbs array - don't return link, just name of the element. To avoid cannibalization
            if (idx === arr.length - 1) {
                return {
                    '@type': 'ListItem',
                    position: idx + 1,
                    item: {
                        name: breadcrumb.name
                    }
                };
            }

            return {
                '@type': 'ListItem',
                position: idx + 1,
                item: {
                    '@id': location + breadcrumb.path,
                    name: breadcrumb.name
                }
            };
        }),
        null,
        2
    );

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": ${breadcrumbsSchema}
                        }
                    `}
                </script>
            </Helmet>
            <nav className="breadcrumbs">
                <ul className="breadcrumbs__list">
                    {breadcrumbs.map((route, idx, arr) => {
                        // if this is the last element of breadcrumbs array - return span, not link. To avoid cannibalization
                        if (idx === arr.length - 1) {
                            return (
                                <span className="breadcrumbs__item" key={ idx }>
                                    {route.name}
                                </span>
                            );
                        }
                        return (
                            <Link key={ idx } to={ route.path } className="breadcrumbs__item">
                                {route.name}
                            </Link>
                        );
                    })}
                </ul>
            </nav>
        </>
    );
};

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.instanceOf(Array).isRequired
};
