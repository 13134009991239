import React from 'react';

import { ScrollView } from './view';

export const Scroll = () => {
    const scroll = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (<ScrollView scroll={ scroll } />);
};