import { graphql, useStaticQuery } from 'gatsby';
import { generateSlug } from 'utilities/helpers/generateSlug';

export const useSeoTexts = (category, type) => {
    const data = useStaticQuery(graphql`
        query {
            seotexts {
                data {
                    category_name
                    meta_title
                    meta_description
                    description
                    filters {
                        filter_description
                        filter_name
                        filter_meta_title
                        filter_meta_description
                        filter_is_active
                        filter_h1
                    }
                }
            }
        }
    `);

    const articleSeo = data.seotexts.data.find(
        (d) => d.category_name === category || generateSlug(d.category_name) === category
    );
    const filterSeo = articleSeo?.filters?.find(
        (filter) => generateSlug(filter.filter_name) === type?.value && Boolean(+filter.filter_is_active)
    );
    const description = type ? filterSeo?.filter_description : articleSeo?.description;
    const filterHeader = filterSeo?.filter_h1;

    const metaTitle = type ? filterSeo?.filter_meta_title : articleSeo?.meta_title;
    const metaDescription = type ? filterSeo?.filter_meta_description : articleSeo?.meta_description;

    return {
        description,
        metaDescription,
        metaTitle,
        filterHeader
    };
};
