import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Navigation } from '../utilities/components/navigation/component';
import { BannerView } from '../utilities/components/banner/component/view';
import { Articles } from '../features/technologies/articles/component';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

const Technologies = () => {
    const data = useStaticQuery(graphql`
        query GetTechnologiesData {
            banners(site_type: { eq: "Technologie" }) {
                image
                alt_image
                background_position_x
            }
        }
    `);

    const breadcrumbs = [routes.home, routes.technologie];

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Technologie');

    return (
        <main className="technologies">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Technologie - Dowiedz się więcej o naszych technologiach' }
                description={
                    metaDescription
                    || 'Poznaj markę Barański, sprawdź jak wykorzystujemy nowoczesne technologie w procesie tworzenia. Przeczytaj więcej o naszych technologiach.'
                }
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                alt={ data?.banners?.alt_image }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Technologie"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Articles />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Technologies;
