import React from 'react';
import PropTypes from 'prop-types';

import { HeadlineView } from '../../../../utilities/components/headline/component/view';
import { ArticleView } from '../../../../utilities/components/article/component/view';
import { ButtonView } from '../../../../utilities/components/button/component/view';
import { Scroll } from '../../../../utilities/components/scroll/component';

import './view.scss';
import { paths } from 'utilities/routes';

export const ArticlesView = ({ articles, types }) => (
    <section className="articles">
        {
            types.map((currentType) => (
                <div className="articles__container">
                    <HeadlineView text={ currentType } />
                    <div className="articles__list">
                        {
                            articles?.filter((currentArticle) => (currentArticle.subtype === currentType))?.map((currentArticle, index) => index < 3 && (
                                <ArticleView
                                    alternative_id={ currentArticle.alternative_id }
                                    image_main={ currentArticle.image_main }
                                    author={ currentArticle.author }
                                    created_at={ currentArticle.created_at }
                                    title={ currentArticle.title }
                                    slug={ currentArticle.slug }
                                    content={ currentArticle.lead }
                                    link="/"
                                    isSmall
                                    key={ index }
                                />
                            ))
                        }
                    </div>
                    <ButtonView text="Zobacz więcej" link={ paths.wszystkoODrzwiach } />
                    <Scroll />
                </div>
            ))
        }
    </section>
);

ArticlesView.propTypes = {
    articles: PropTypes.instanceOf(Array).isRequired,
    types: PropTypes.instanceOf(Array).isRequired
};