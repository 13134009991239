import React from 'react';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import arrow from 'assets/images/arrow--white.svg';

import './view.scss';

export const ScrollView = ({ scroll }) => (
    <button
        className="scroll"
        type="button"
        onClick={ () => {
            scroll();
        } }
    >
        <Img className="scroll__icon" src={ arrow } alt="strzałka" />
    </button>
);

ScrollView.propTypes = {
    scroll: PropTypes.func.isRequired
};